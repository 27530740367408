@import "../../assets/styles/_variables.scss";

.dashboardContainer {
  width: calc(100% - 280px);
  height: 100vh;
  padding: 3rem;
  margin-left: 280px;

  @include mobile() {
    width: 100%;
    padding: 5rem 1rem 3rem;
    margin-left: 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile() {
      display: block;
    }
    .heading {
      h2 {
        font-size: clamp(1rem, 4vw, 1.375rem);
        font-weight: normal;
        color: #333333;
      }
      h1 {
        font-size: clamp(1.75rem, 4vw, 2.75rem);
        font-weight: bold;
      }
    }
    .logout-btn {
      display: flex;
      background-color: $primary-color;
      color: #fff;
      padding: 8px 20px;
      border-radius: 8px;
      align-items: center;
      gap: 10px;
    }
  }

  .wrapper {
    border: 1px solid #cacaca;
    border-radius: 20px;
    padding: 2rem;
    margin: 2rem 0;

    input {
      border: 2px solid #cacaca;
      border-radius: 8px;
      padding: 0 10px;

      &:focus{
        outline: none;
      }
    }

    .filter-btn {
      display: flex;
      border: 2px solid $primary-color;
      color: $primary-color;
      padding: 8px 20px;
      border-radius: 25px;
      align-items: center;
      gap: 10px;

      img {
        width: 15px;
      }
    }

    .new-arrivals {
      font-size: 1.125rem;
      font-weight: medium;
    }

    .text {
      font-size: 0.75rem;
      color: #b5b5c3;
    }

    .table-container {
      width: 100%;
      overflow-x: auto;
      margin-top: 2rem;

      .responsive-table {
        width: 100%;
        border-collapse: collapse;

        th {
          font-size: 1.125rem;
          font-weight: 600;

          img {
            display: inline-block;
            cursor: pointer;
          }
        }

        th,
        td {
          white-space: nowrap;
          height: 70px;
          padding: 12px 15px;
          color: #333333;
          text-align: left;
          align-items: center;
        }

        td {
          border-bottom: 1px solid #cacaca;
          font-size: 1rem;
          font-weight: 400;

          img:hover {
            opacity: 0.6;
          }
        }

        thead {
          background-color: #f3f6f9;
          border-radius: 12px;
          width: 100%;
        }
      }
    }

    @include mobile() {
      .responsive-table {
        td {
          text-align: right;
          padding-left: 50%;
          position: relative;

          &::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
