@import "../../assets/styles/_variables.scss";

.SubmittedTrackContainer {
  //   padding: 3rem 10rem;
  width: 100%;
  height: 100%;

  @include mobile() {
    width: 100%;
    margin-left: 0;
  }

  .back {
    position: relative;
    left: -35px;
    top: 44px;
    cursor: pointer;

    @include mobile() {
      left: 0;
      top: 0;
    }
  }

  .wrapper {
    border: 1px solid #cacaca;
    border-radius: 20px;
    padding: 2rem;
    margin: 2rem 0;

    .table-container {
      width: 100%;
      overflow-x: auto;

      .responsive-table {
        width: 100%;
        border-collapse: collapse;

        th {
          font-size: 1.125rem;
          font-weight: 600;
        }

        th,
        td {
          white-space: nowrap;
          height: 70px;
          padding: 12px 15px;
          color: #333333;
          text-align: left;
          align-items: center;
        }

        td {
          border-bottom: 1px solid #cacaca;
          font-size: 1rem;
          font-weight: 400;

          img:hover{
            opacity: 0.6;
          }
        }

        thead {
          background-color: #f3f6f9;
          border-radius: 12px;
          width: 100%;
        }
      }
    }

    @include mobile() {
      .responsive-table {
        td {
          text-align: right;
          padding-left: 50%;
          position: relative;

          &::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }
  }
}
