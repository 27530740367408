@import "../../assets/styles/_variables.scss";

.uploadTrackContainer {
  padding: 3rem;
  margin-left: 280px;
  width: calc(100% - 280px);
  height: 100vh;

  @include mobile() {
    width: 100%;
    padding: 5rem 1rem 3rem;
    margin-left: 0;
  }

  .title {
    h2 {
      font-size: 1.375rem;
      font-weight: normal;
    }
    h1 {
      font-size: clamp(1.75rem, 4vw, 2.75rem);
      font-weight: bold;
    }
  }

  .wrapper {
    input[type="file"] {
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .uploaded-track{
      background-color: $primary-color;
      float: right;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
    }

    .file-input {
      border: 2px dashed #666666;
      width: 100%;
      height: 214px;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.25rem;
      font-weight: regular;
      color: #111111;
      text-align: center;
      gap: 10px;
    }
    span {
      color: #666666;
      font-size: 1.25rem;
    }

    .layout {
      padding: 2rem 0;

      h2 {
        font-size: clamp(1rem, 4vw, 1.875rem);
        font-weight: 600;
      }

      input[type="text"],
      input[type="password"],
      select {
        width: 100%;
        height: 4.625rem;
        padding: 0 1rem;
        border: none;
        border-bottom: 1px solid #cacaca;
        background: #fff;
        border-radius: 0;
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;

        &::placeholder {
          font-size: clamp(1rem, 3vw, 1.25rem);
          font-weight: regular;
          color: #666666;
        }

        &:focus {
          outline: none;
          background: #fff;
        }

        @include mobile() {
          height: 3rem;
        }
      }

      select {
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;

        option {
          font-size: clamp(1rem, 3vw, 1.25rem);
          font-weight: regular;
          color: #666666;
        }
      }

      .disclaimer {
        padding: 2rem 0;

        .heading {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          align-items: center;
          p {
            font-size: clamp(0.75rem, 3vw, 1.375rem);
            font-weight: 600;
            // text-transform: uppercase;
          }

          input {
            accent-color: $primary-color;
            cursor: pointer;
            width: 18px;
            height: 18px;

            @include mobile() {
              width: 12px;
              height: 12px;
            }
          }
        }

        .text {
          padding: 1rem 0;
          span {
            display: block;
            font-size: clamp(0.75rem, 3vw, 1.125rem);
            color: #666666;
          }
          .toggle-btn {
            background: none;
            border: none;
            color: $primary-color;
            cursor: pointer;
            padding: 0;
            margin: 0;
            display: inline;
            font-weight: bold;
            font-size: clamp(0.75rem, 3vw, 1.125rem);
          }
        }
      }

      .submit-btn {
        width: 100%;
        background-color: $primary-color;
        text-align: center;
        font-size: clamp(1rem, 3vw, 1.375rem);
        color: #fff;
        height: 3.5rem;
        text-transform: uppercase;

        @include mobile() {
          height: 3rem;
        }
      }
    }
  }

  .success-container {
    width: 75%;
    margin-top: 0.75rem;

    @include mobile() {
      width: 100%;
    }

    .text {
      display: flex;
      justify-content: space-between;

      @include mobile() {
        display: grid;
      }
    }

    .submit-btn {
      width: 100%;
      background-color: $primary-color;
      text-align: center;
      font-size: clamp(1rem, 3vw, 1.375rem);
      color: #fff;
      height: 3.5rem;
      text-transform: uppercase;

      @include mobile() {
        height: 3rem;
      }
    }
  }
}
