@import "../../assets/styles/variables";

.filter-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;

  .input-box,
  input[type="date"] {
    width: 100%;
    height: 3.5rem;
    border: 1px solid $primary-color;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .input-field {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    border: 1px solid #646467;
    border-radius: 12px;
    padding: 1rem;
    overflow: hidden;

    @include mobile() {
      display: block;
      max-height: 12rem;
      overflow: scroll;
    }
  }
  .input-field p {
    display: flex;
    align-items: center;
    flex: 1 1 calc(25% - 0.5rem);
    min-width: 0;
    white-space: nowrap;
  }

  .input-field input {
    margin-right: 10px;
    accent-color: $primary-color;
    cursor: pointer;
  }

  .datePicker {
    display: flex;
    gap: 1.25rem;
    align-items: center;

    @include mobile() {
      display: block;
    }

    .input-date {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      input[type="date"] {
        text-transform: uppercase;
        background-color: #fbfcfe;
      }
    }
  }
}
