@import "../../assets/styles/_variables.scss";

.SignupContainer {
  // margin: 6rem 10rem;
  width: 1000px;
  margin: auto;
  padding: 4rem 0;

  @include mobile {
    margin: 0.625rem;
  }

  .title {
    .welcome_text {
      position: relative;
      font-size: clamp(2rem, 4vw, 3rem);
      font-weight: bold;
      color: $primary-black;
      align-items: center;
      display: inline-flex;

      .logo {
        position: absolute;
        left: -100px;

        @include mobile{
          position: relative;
          left: 0;
        }

        img {
          width: 80px;
        }
      }
    }

    .text {
      font-size: clamp(1rem, 3vw, 1rem);
      font-weight: regular;
      color: $primary-black;

      span {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

  .layout {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include mobile() {
      gap: 1.5rem;
    }

    input[type="text"],
    input[type="password"],
    select, .select {
      width: 100%;
      height: 3.5rem;
      border: 1px solid #646467;
      border-radius: 5px;
      padding: 0 1rem;
      background-color: #fff;

      &::placeholder {
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;
      }

      &:focus {
        outline: none;
      }

      @include mobile() {
        height: 3rem;
      }
    }

    select {
      font-size: clamp(1rem, 3vw, 1.25rem);
      font-weight: regular;
      color: #666666;

      option {
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;
      }
    }

    .select::-ms-expand {
      display: none;
    }

    .musical-groups {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        // position: absolute;
        right: 10px;
        cursor: pointer;
        color: #9b9a9c;
        width: 2rem;

        @include mobile(){
          position: absolute;
          right: 1px;
          width: 1.5rem;
        }
      }
    }

    .password-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;

      .hide_password {
        position: absolute;
        right: 10px;
        cursor: pointer;
        color: #9b9a9c;
        font-size: clamp(1rem, 3vw, 1.25rem);
      }
    }

    input[type="checkbox"] {
      accent-color: $primary-color;
      cursor: pointer;
      width: 18px;
      height: 18px;
    }

    p {
      font-size: clamp(1rem, 3vw, 1.25rem);
      font-weight: regular;

      span {
        color: $primary-color;
      }
    }

    .signup-btn {
      width: 100%;
      background-color: $primary-color;
      text-align: center;
      font-size: clamp(1rem, 3vw, 1.375rem);
      color: #fff;
      height: 3.5rem;
      text-transform: uppercase;

      @include mobile() {
        height: 3rem;
      }
    }
  }

  .line_break {
    width: 100%;
    text-align: center;

    @include mobile() {
      padding: 1rem 0;
    }

    hr {
      border-top: 1px solid #777777;
    }
    span {
      display: block;
      position: relative;
      top: -17px;
      background-color: #fff;
      color: #777777;
      width: 2rem;
      margin: auto;
      font-size: clamp(1rem, 3vw, 1.25rem);
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    text-align: center;

    @include mobile() {
      gap: 1.5rem;
    }

    p {
      width: 100%;
      display: flex;
      height: 3.5rem;
      border: 1px solid #646467;
      border-radius: 5px;
      align-items: center;
      border: 1px solid #040308;
      justify-content: center;
      gap: 4px;
      cursor: pointer;

      @include mobile() {
        height: 3rem;
      }
    }
  }
}

.react-tel-input .selected-flag:hover, 
.react-tel-input .selected-flag:focus {
    background-color: unset !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: unset !important;
}
