@import "../../assets/styles/_variables.scss";

.forgotPwdContainer {
  margin: auto 10rem;

  @include mobile {
    margin: 0.625rem;
  }

  .wrapper {
    align-items: center;
    height: 100vh;

    .password_container {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      @include mobile(){
        gap: 1.5rem;
      }

      h1 {
        font-size: clamp(2rem, 4vw, 3rem);
        font-weight: bold;
        color: $primary-black;
      }

      input {
        width: 100%;
        height: 3.5rem;
        border: 1px solid #646467;
        border-radius: 5px;
        padding-left: 1rem;

        &::placeholder {
          font-size: clamp(1rem, 3vw, 1.25rem);
          font-weight: regular;
          color: #666666;
        }

        &:focus {
          outline: none;
        }

        @include mobile() {
          height: 3rem;
        }
      }

      .send-btn {
        width: 100%;
        background-color: $primary-color;
        text-align: center;
        font-size: clamp(1rem, 3vw, 1.375rem);
        color: #fff;
        height: 3.5rem;
        text-transform: uppercase;

        @include mobile(){
          height: 3rem;
        }
      }

      .backToLogin {
        width: 100%;
        text-align: center;
        font-size: clamp(1rem, 3vw, 1.375rem);
        height: 3.5rem;
        border: 1px solid #646467;
        border-radius: 5px;

        @include mobile(){
          height: 3rem;
        }
      }
    }
  }
}

.backgroundImage {
  position: relative;
  height: 100vh;
  width: 100%;

  .full-size {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include mobile() {
      width: 95%;
    }

    img {
      margin: auto;
      width: clamp(30%, 50%, 100%);
    }

    .text {
      font-size: clamp(1rem, 3vw, 1.375rem);
      font-weight: 600;
      color: $primary-black;
      text-align: center;
    }
    .send-btn {
      width: 100%;
      background-color: $primary-color;
      text-align: center;
      font-size: clamp(1rem, 3vw, 1.375rem);
      color: #fff;
      height: 4.625rem;
      text-transform: uppercase;

      @include mobile() {
        height: 3rem;
      }
    }
  }
}
