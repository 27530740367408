@import "../../assets/styles/_variables.scss";

.userProfileContainer {
  padding: 3rem;
  margin-left: 280px;
  width: calc(100% - 280px);
  height: 100vh;

  @include mobile() {
    width: 100%;
    padding: 5rem 1rem 3rem;
    margin-left: 0;
  }

  .back {
    position: relative;
    left: -35px;
    top: 44px;
    cursor: pointer;

    @include mobile() {
      left: 0;
      top: 0;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile() {
      display: block;
    }
    .heading {
      h1 {
        font-size: clamp(1.5rem, 4vw, 2.125rem);
        font-weight: bold;
        color: $primary-color;
        cursor: pointer;
        text-transform: capitalize;
      }
      p {
        font-size: clamp(1rem, 4vw, 1.375rem);
        font-weight: normal;
        color: #333333;
      }
    }

    .tracks-btn {
      border: 2px solid $primary-color;
      color: $primary-color;
      padding: 8px 20px;
      border-radius: 12px;
      align-items: center;
    }

    .tracks-btn.active {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .layout {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid #cacaca;
    border-radius: 20px;
    padding: 2rem;
    margin: 2rem 0;

    @include mobile() {
      gap: 1.5rem;
    }

    .input-container {
      width: 50%;

      @include mobile() {
        width: 100%;
        display: block;
      }
    }

    label {
      font-size: 1rem;
      font-weight: 500;
    }

    input[type="text"],
    input[type="password"],
    select {
      width: 100%;
      height: 3.5rem;
      border-radius: 5px;
      padding: 0 1rem;
      background-color: #f2f2f2 !important;
      margin-top: 10px;
      text-transform: capitalize;

      &::placeholder {
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;
      }

      &:focus {
        outline: none;
      }

      @include mobile() {
        height: 3rem;
      }
    }
    .password-wrapper {
      width: 100%;
      position: relative;
      gap: 1rem;

      .hide_password {
        position: absolute;
        right: 10px;
        top: 50%;
        cursor: pointer;
        color: #9b9a9c;
        font-size: clamp(1rem, 3vw, 1.25rem);
      }
    }

    .delete-btn {
      width: 100%;
      background-color: $primary-color;
      text-align: center;
      font-size: clamp(1rem, 3vw, 1.375rem);
      color: #fff;
      height: 3.5rem;
      text-transform: uppercase;

      @include mobile() {
        height: 3rem;
      }
    }
  }
}
