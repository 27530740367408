@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;;
}

button{
  &:hover{
    opacity: 0.8;
  }
}