@import "../../assets/styles/_variables.scss";

.submissionContainer {
  padding: 3rem;
  margin-left: 280px;
  width: calc(100% - 280px);
  height: 100vh;

  @include mobile() {
    width: 100%;
    padding: 5rem 1rem 3rem;
    margin-left: 0;
  }

  .title {
    h2 {
      font-size: clamp(1rem, 4vw, 1.375rem);
      font-weight: normal;
      color: #333333;
    }
    h1 {
      font-size: clamp(1.75rem, 4vw, 2.75rem);
      font-weight: bold;
    }
  }

  .wrapper {
    border: 1px solid #cacaca;
    border-radius: 20px;
    padding: 2rem;
    margin: 2rem 0;

    .table-container {
      width: 100%;
      overflow-x: auto;

      .responsive-table {
        width: 100%;
        border-collapse: collapse;

        th{
          font-size: 1.125rem;
          font-weight: 600;
        }

        th,
        td {
          white-space: nowrap;
          height: 70px;
          padding: 12px 15px;
          color: #333333;
          text-align: left;
        }

        td{
          border-bottom: 1px solid #cacaca;
          font-size: 1rem;
          font-weight: 400;

          img:hover{
            opacity: 0.6;
          }
        }

        thead {
          background-color: #F3F6F9;
          border-radius: 12px;
        }
      }
    }

    @include mobile() {
      .responsive-table {
        td {
          text-align: right;
          padding-left: 50%;
          position: relative;

          &::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }
  }
}
