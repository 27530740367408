// Color Variables
$primary-color: #e26639;
$primary-black: #000000;

// Responsive Breakpoints
$mobile: 768px;
$desktop: 769px;

// Example of responsive design using variables

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}
