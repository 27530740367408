@import "../../assets/styles/_variables.scss";

.profileContainer {
  width: calc(100% - 280px);
  height: auto;
  padding: 3rem;
  margin-left: 280px;

  @include mobile() {
    width: 100%;
    padding: 5rem 1rem 3rem;
    margin-left: 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    .btn {
      width: 100%;
      text-align: center;
      background-color: #fff;
      border: 2px solid $primary-color;
      height: 3.5rem;
      font-size: clamp(1rem, 4vw, 1.375rem);
      text-transform: capitalize;
    }

    .btn.active {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .layout {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid #cacaca;
    border-radius: 20px;
    padding: 2rem;
    margin: 2rem 0;

    @include mobile() {
      gap: 1.5rem;
      padding: 1rem;
    }

    .input-container {
      width: 50%;

      @include mobile() {
        width: 100%;
        display: block;
      }
    }

    label {
      font-size: 1rem;
      font-weight: 500;
    }

    input[type="text"],
    input[type="password"] {
      width: 100%;
      height: 3.5rem;
      border-radius: 5px;
      padding: 0 1rem;
      background-color: #f2f2f2;
      margin-top: 10px;
      text-transform: capitalize;

      &::placeholder {
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;
      }

      &:focus {
        outline: none;
      }

      @include mobile() {
        height: 3rem;
      }
    }

    .footer {
      display: flex;
      justify-content: end;
      gap: 1rem;

      .back-btn {
        text-align: center;
        font-size: clamp(1rem, 3vw, 1rem);
        color: $primary-color;
        height: 2.5rem;
        border-radius: 8px;
        text-transform: uppercase;
        border: 2px solid $primary-color;
        padding: 0 20px;

        @include mobile() {
          height: 3rem;
        }
      }

      .delete-btn {
        background-color: $primary-color;
        text-align: center;
        font-size: clamp(1rem, 3vw, 1rem);
        color: #fff;
        height: 2.5rem;
        border-radius: 8px;
        text-transform: uppercase;
        padding: 0 20px;

        @include mobile() {
          height: 3rem;
        }
      }
    }
  }
}
