@import "../../assets/styles/_variables.scss";

.SideMenuBar {
  width: 280px;
  height: 100vh;
  background-color: #101010;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  z-index: 1;

  ul {
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    
    li {
      display: flex;
      gap: 10px;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      height: 48px;
      align-items: center;
      padding: 0 10px;
      border-radius: 10px;
      cursor: pointer;
    }

    li.active {
      background-color: $primary-color;
    }
  }

  @include mobile {
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    // z-index: 0;

    &.open {
      transform: translateX(0);
    }

    ul {
      flex-direction: column;
      gap: 1rem;

      li {
        font-size: 1rem;
        height: auto;
        padding: 0.75rem;
        text-align: center;
      }
    }
  }
}

.topHeader {
  display: none;
  width: 100%;
  height: 60px;
  background-color: #101010;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 2;

  h1 {
    font-size: 1.25rem;
  }

  .menuToggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;
  }

  @include mobile() {
    display: flex;
  }
}

