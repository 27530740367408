@import "../../assets/styles/_variables.scss";

.loginContainer {
  width: 500px;
  margin: 10rem auto;

  @include mobile {
    margin: auto;
    padding: 1rem;
  }

  .title {
    .welcome_text {
      position: relative;
      display: inline-flex;
      font-size: clamp(2rem, 4vw, 3rem);
      font-weight: bold;
      color: $primary-black;
      align-items: center;

      .logo {
        position: absolute;
        left: -100px;

        @include mobile{
          position: relative;
          left: 0;
        }

        img {
          width: 80px;
        }
      }
    }

    .text {
      font-size: clamp(1rem, 3vw, 1rem);
      font-weight: regular;
      color: $primary-black;

      span {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

  .layout {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include mobile() {
      gap: 1.5rem;
    }

    input {
      width: 100%;
      height: 3.5rem;
      border: 1px solid #646467;
      border-radius: 5px;
      padding-left: 1rem;

      &::placeholder {
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: regular;
        color: #666666;
      }

      &:focus {
        outline: none;
      }

      @include mobile() {
        height: 3rem;
      }
    }

    .password-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;

      .hide_password {
        position: absolute;
        right: 10px;
        cursor: pointer;
        color: #9b9a9c;
        font-size: clamp(1rem, 3vw, 1.25rem);
      }
    }

    label {
      font-size: clamp(1rem, 3vw, 1.25rem);
      font-weight: regular;
      color: $primary-color;
      cursor: pointer;
    }

    .login-btn {
      width: 100%;
      background-color: $primary-color;
      text-align: center;
      font-size: clamp(1rem, 3vw, 1.375rem);
      color: #fff;
      height: 3.5rem;
      text-transform: uppercase;

      @include mobile() {
        height: 3rem;
      }
    }
  }

  .line_break {
    width: 100%;
    text-align: center;
    padding: 1rem 0;

    hr {
      border-top: 1px solid #777777;
    }

    span {
      display: block;
      position: relative;
      top: -17px;
      background-color: #fff;
      color: #777777;
      width: 2rem;
      margin: auto;
      font-size: clamp(1rem, 3vw, 1.25rem);
    }
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}